import { create } from 'zustand'
import { persist, createJSONStorage } from 'zustand/middleware'

const useAuthStore = create(persist(
    (set) => ({
        token: null,
        setToken: (val) => set({ token: val }),
    }),
    {
        name: 'token-storage',
        storage: createJSONStorage(() => localStorage)
    }
))

export default useAuthStore