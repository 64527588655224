import { useEffect } from "react"
import { RiCloudFill, RiCloudLine, RiHotelBedLine, RiListOrdered2, RiLogoutBoxLine, RiNotification4Line, RiSunFill, RiSunLine, RiTicketLine } from "react-icons/ri"

import useCashierStore from "../stores/cashier";
import axios_client from "../helpers/axios";
import useAuthStore from "../stores/auth";
import { Link } from "react-router-dom";
import { saveAs } from 'file-saver';

const Header = () => {

    const { cashier, setCashier } = useCashierStore();
    const { setToken } = useAuthStore();

    useEffect(() => {
        getSellerData()
    }, [])

    const getSellerData = async () => {
        const { data: seller_result } = await axios_client.get("/me");
        setCashier(seller_result?.cashier)
    }

    const handleExport = async () => {
        const { data: statistics_result } = await axios_client.post("/statistics");
        getSellerData();
        window.location.href = statistics_result;
    }

    return (
        <header className="bg-white py-6">
            <div className="container mx-auto flex flex-row items-center justify-between">
                <div className="flex flex-row">
                    <Link to={"dashboard"}><img src="/logo.svg" width={160} alt="" /></Link>
                    <div className="flex flex-col border-l border-l-slate-200 pl-4 ml-4">
                        <span className="text-sm font-light leading-4 mb-1">Kasiyer Paneli</span>
                        <span className="text-base font-semibold leading-4">{cashier?.museum_name}</span>
                    </div>
                </div>
                <div>

                    <div class="flex flex-row justify-center relative items-center">
                        <div class="flex flex-col justify-center text-right mr-3 text-lg">
                            <div class="font-bold text-base leading-5 uppercase">{cashier?.fullname}</div>
                            <div class="font-light text-base leading-5">Kasiyer</div>
                        </div>
                        <div className="flex flex-row items-center gap-x-2">
                            <button onClick={() => {
                                setToken(null);
                                delete axios_client.defaults.headers.authorization;
                                window.location.href = "/"
                            }} class="w-10 h-10 bg-red-600 rounded flex flex-col justify-center items-center cursor-pointer text-white text-lg">
                                <RiLogoutBoxLine />
                            </button>
                            <button onClick={handleExport} class={`w-10 h-10 bg-slate-100 text-slate-800 rounded flex flex-col justify-center items-center cursor-pointer text-lg`}>
                                {cashier?.eotd ? <RiCloudLine /> : <RiSunLine />}
                            </button>
                            <span className="text-xs px-4">&mdash;</span>
                            <Link to={"tickets"} class="w-10 h-10 bg-green-500 rounded flex flex-col justify-center items-center cursor-pointer text-white text-lg">
                                <RiTicketLine />
                            </Link>
                            <Link to={"logs"} class="w-10 h-10 bg-lime-500 rounded flex flex-col justify-center items-center cursor-pointer text-white text-lg">
                                <RiListOrdered2 />
                            </Link>
                            <Link to={"notifications"} class="w-10 h-10 bg-blue-500 rounded flex flex-col justify-center items-center cursor-pointer text-white text-lg">
                                <RiNotification4Line />
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    )
}

export default Header