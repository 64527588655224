import React, { useEffect, useState } from 'react';

import { createColumnHelper } from '@tanstack/react-table';
import { LoadingOverlay } from '@mantine/core';
import { RiTeamLine } from "react-icons/ri";

import DataTable from '../components/table';

import axios from '../helpers/axios';

export default function TransactionList() {

    const [tickets, setTickets] = useState([])
    const [loading,] = useState(false);

    useEffect(() => {
        getNotifications();
    }, [])

    const getNotifications = async () => {
        try {
            const { data: notifications_result } = await axios.get("/notifications");
            setTickets(notifications_result?.notifications)
        } catch (error) {
            // notifications.show({ title: "Uyarı", color: "red", message: error?.response?.data?.message || "Sistem Hatası!" })
        }
    }

    const columnHelper = createColumnHelper()
    const columns = [
        columnHelper.accessor('id', {
            header: '#',
        }),
        columnHelper.accessor('title', {
            header: 'BAŞLIK',
            truncate: true,
        }),
        columnHelper.accessor('description', {
            header: 'Açıklama',
            truncate: true,
            full: true,
        }),
    ]

    return (
        <div className="bg-slate-50 muhsin flex flex-col">

            <LoadingOverlay className='fixed left-0 right-0 top-0 bottom-0 z-[100]' visible={!!loading} overlayBlur={5} />

            <div className='flex-1 flex flex-col'>

                <div className='bg-red-500'>
                    <div className='container mx-auto flex flex-row items-center py-6'>
                        <RiTeamLine color="white" size={18} />
                        <h1 className='text-white text-lg font-light pl-2'>BİLET YÖNETİMİ</h1>
                    </div>
                </div>

                <div className='container mx-auto grid grid-cols-1 gap-x-6 gap-y-3 py-6'>

                    <div className='bg-white rounded shadow-sm border border-slate-100 border-b-transparent'>
                        <div className='h-[70px] flex flex-row items-center justify-between px-6 border-b border-b-slate-100'>
                            <div className='flex-1 flex flex-row'>
                                <h1 className='text-lg font-bold'>KASİYER BİLETLERİ</h1>
                            </div>
                        </div>
                        <div className='w-full overflow-x-auto'>
                            <DataTable columns={columns} data={tickets} />
                        </div>
                    </div>

                </div>

            </div>

        </div>
    );
}