import React, { useEffect, useMemo, useState } from 'react';

import { createColumnHelper } from '@tanstack/react-table';
import { Input, LoadingOverlay } from '@mantine/core';
import { RiTeamLine } from "react-icons/ri";

import DataTable from '../components/table';

import axios from '../helpers/axios';

export default function TransactionList() {

    const [logs, setLogs] = useState([])
    const [keyword, setKeyword] = useState(null)
    const [loading,] = useState(false);

    const entities = useMemo(() => keyword?.length > 0 ? logs.filter(l => l.position == keyword) : logs, [keyword])

    useEffect(() => {
        getLogs();
    }, [])

    const getLogs = async () => {
        try {
            const { data: logs_result } = await axios.get("/logs");
            setLogs(logs_result?.logs || [])
            setKeyword("")
        } catch (error) {
            setLogs([])
        }
    }

    const columnHelper = createColumnHelper()
    const columns = [
        columnHelper.accessor('position', {
            header: '#',
            truncate: true
        }),
        columnHelper.accessor('message', {
            header: 'BAŞLIK',
            truncate: true,
            full: true
        }),
        columnHelper.accessor('time', {
            header: 'İŞLEM ZAMANI',
            truncate: true,
        }),
    ]

    return (
        <div className="bg-slate-50 muhsin flex flex-col">

            <LoadingOverlay className='fixed left-0 right-0 top-0 bottom-0 z-[100]' visible={!!loading} overlayBlur={5} />

            <div className='flex-1 flex flex-col'>

                <div className='bg-red-500'>
                    <div className='container mx-auto flex flex-row items-center py-6'>
                        <RiTeamLine color="white" size={18} />
                        <h1 className='text-white text-lg font-light pl-2'>HATA YÖNETİMİ</h1>
                    </div>
                </div>

                <div className='container mx-auto grid grid-cols-1 gap-x-6 gap-y-3 py-6'>

                    <div className='bg-white rounded shadow-sm border border-slate-100 border-b-transparent'>
                        <div className='h-[70px] flex flex-row items-center justify-between px-6 border-b border-b-slate-100'>
                            <div className='flex-1 flex flex-row'>
                                <h1 className='text-lg font-bold'>BİLET KAYITLARI</h1>
                            </div>
                            <div className='flex flex-row items-center gap-x-3'>
                                <span>Bilet Sırasına Göre Ara:</span>
                                <Input value={keyword} onChange={e => setKeyword(e.target.value)} size='sm' />
                            </div>
                        </div>
                        <div className='w-full overflow-x-auto'>
                            <DataTable paginate columns={columns} data={entities} />
                        </div>
                    </div>

                </div>

            </div>

        </div>
    );
}