import React from 'react';
import { Routes, Route, Outlet } from 'react-router-dom';

//stores
import useAuthStore from './stores/auth';

// pages
import Login from "./pages/login";
import Dashboard from './pages/dashboard';
import Notifications from './pages/notifications';
import Tickets from './pages/tickets';
import Logs from './pages/logs';

//components
import Header from './layouts/header';
import Footer from './layouts/footer';

const Navigation = () => {

    const { token } = useAuthStore()

    const PrivateRoute = () => {

        if (!token) {
            return <h1>asd</h1>
        }

        return (
            <>
                <Header />
                <Outlet />
                <Footer />
            </>
        )
    }

    return (
        <Routes>
            <Route element={<PrivateRoute />}>
                <Route path='dashboard' element={<Dashboard />} />
                <Route path='notifications' element={<Notifications />} />
                <Route path='logs' element={<Logs />} />
                <Route path='tickets' element={<Tickets />} />
            </Route>
            <Route path='/' element={<Login />} />
            <Route path='*' element={<Login />} />
        </Routes>
    )
};

export default Navigation;