import QRCode from 'react-qr-code'
import TicketBg from '../assets/img/ticket-bg.jpg'

const PrintPage = ({ reff, items }) => {
    return (
        <div id="print-wrapper" ref={reff} className="w-full flex flex-col">
            {items?.map(t => {
                return (
                    <div className="w-[359px] h-[820px] flex flex-col items-center justify-end pb-8 break-after-auto block bg-cover bg-no-repeat bg-center print:bg-none" style={{ backgroundImage: `url(${TicketBg})` }}>
                        <div className='mb-6 border-4 border-white'>
                            <QRCode level={"L"} size={100} value={t?.code || ""} />
                        </div>
                        <span className='text-center text-xl font-bold uppercase'>{t?.fullname || "-"}</span>
                        <span className='text-center text-base font-light'>{t?.ticket_type || ""}</span>
                        <span className='text-center text-sm font-light'>#{t?.position || ""}</span>
                    </div>
                )
            })}
        </div>
    )
}

export default PrintPage