const Footer = () => {
    return (
        <footer class="bg-white border-t-1 border-ddd py-6">
            <div class="container mx-auto flex justify-between">
                <span class="font-light text-slate-800">©2024 — <strong>fibacode.com</strong></span>
                <a href="mailto:muhsin@fibanet.com" class="font-light text-slate-800 cursor-pointer">Destek</a>
            </div>
        </footer>
    )
}

export default Footer