import { create } from 'zustand'

const useCartStore = create((set) => ({

    // id 
    items: [],

    // aynından varsa güncelle
    addItem: (entity) => set((state) => ({ items: ([...state.items, entity]) })),
    deleteItem: (ticket_id) => set((state) => ({ items: state.items.filter(c => c.ticket_id !== ticket_id) })),
    updateQty: (id, isRising) => set((state) => ({ items: state.items?.map(i => i.ticket_id === id ? { ...i, ...{ qty: isRising ? i.qty + 1 : i.qty - 1 } } : i) })),
    clearItems: () => set(() => ({ items: [] })),

}))

export default useCartStore