import { useEffect, useMemo, useRef, useState } from "react";
import { RiSubtractLine, RiAddLine, RiTicketLine, RiPrinterLine, RiCloseLine } from "react-icons/ri";

import axios from "../helpers/axios";
import useTicketStore from "../stores/ticket";
import useCartStore from "../stores/cart";

import { notifications as push } from '@mantine/notifications';
import { Input, Select } from "@mantine/core";

import { useReactToPrint } from 'react-to-print';
import Print from '../components/print';
import toThousand from '../helpers/number';
import PrintPage from "../components/print";

const Dashboard = () => {

    const { items: tickets, setItems: setTicket } = useTicketStore();
    const { items: carts, addItem: pushCart, deleteItem: deleteCart, updateQty: updateCartItem, clearItems } = useCartStore();

    const [notifications, setNotifications] = useState([]);
    const [user, setUser] = useState({ fullname: "-", method: "pos" })

    const [results, setResults] = useState(null);

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        onAfterPrint: () => {
            setResults(null)
            clearItems();
            setUser({ fullname: "-", method: "pos" })
        },
        onPrintError: () => {
            push.show({ title: "Uyarı", color: "red", message: "Bilet yazdırılırken bir sorun oluştu! Lütfen yeniden deneyin!" })
        }
    });

    const totalPrice = useMemo(() => {
        let sum = 0;
        carts?.forEach(c => sum += (c.price * c.qty));
        return sum;
    }, [carts])

    useEffect(() => {
        handleGetHome();
    }, [])

    const handleGetHome = async () => {
        try {
            const { data: home_result } = await axios.get(`/home`);
            setTicket(home_result.tickets)
            setNotifications(home_result?.notifications)
        } catch (error) {
            push.show({ title: "Uyarı", color: "red", message: error?.response?.data?.message || "Sistem Hatası!" })
        }
    };

    const TicketItem = ({ id, name, description, price }) => {

        const findCartItemQty = carts.find(c => c.ticket_id === id)?.qty || 0
        const handlePlus = () => {
            if ((findCartItemQty + 1) === 1) {
                let entity = { ticket_id: id, name: name, price: price, qty: findCartItemQty + 1 };
                pushCart(entity)
                return;
            }
            updateCartItem(id, true)
        }

        const handleSubt = () => {
            if ((findCartItemQty - 1) === 0) {
                deleteCart(id)
                return;
            }
            updateCartItem(id, false)
        }

        return (
            <div className="bg-white border-4 border-slate-100 hover:border-red-500 rounded p-8 flex flex-row items-center justify-between">
                <div>
                    <h1 className="text-xl font-bold text-slate-800 uppercase">{name}</h1>
                    <p className="text-base font-light text-slate-800 lowercase">{description}</p>
                </div>
                <div className="flex flex-row items-center justify-between gap-x-4">
                    <button onClick={handleSubt} className="w-10 h-10 rounded bg-slate-200 flex flex-row items-center justify-center"><RiSubtractLine /></button>
                    <span>{findCartItemQty}</span>
                    <button onClick={handlePlus} className="w-10 h-10 rounded bg-slate-200 flex flex-row items-center justify-center"><RiAddLine /></button>
                </div>
            </div>
        )
    }

    const NotificationItem = ({ title, description }) => {
        return (
            <div className="py-5 px-6 border border-slate-100 flex flex-col items-start justify-between">
                <span className="font-bold text-slate-800 text-base mb-1">{title}</span>
                <div className="text-slate-800 text-sm font-light">{description}</div>
            </div>
        )
    }

    const CartItem = ({ ticket_id, name, price, qty }) => {
        return (
            <li class="flex py-3">
                <div class="w-20 flex flex-row items-center justify-center flex-shrink-0 overflow-hidden rounded border border-slate-200 text-2xl">
                    <RiTicketLine />
                </div>
                <div class="ml-4 flex flex-1 flex-col">
                    <div>
                        <div class="flex justify-between text-base font-medium text-[#362e21]">
                            <h3 class="uppercase font-kalina">MÜZE BİLETİ</h3>
                            <p class="ml-4">₺ {toThousand(price * qty)}</p>
                        </div>
                        <div class="flex flex-row items-center justify-between mt-1">
                            <div class="flex-1 flex flex-col items-start justify-start">
                                <p class="text-sm text-slate-500">Bilet Tipi: <strong>{name}</strong></p>
                                <p class="text-sm text-slate-500">Bilet Adedi: <strong>{qty}</strong></p>
                            </div>
                        </div>
                    </div>
                </div>
            </li>
        )
    }

    const handlePayment = async () => {
        try {
            let items = carts.map(c => ({
                "fullname": user?.fullname || "-",
                "method": user?.method || "other",
                "ticket_id": c.ticket_id,
                "qty": c.qty
            }));
            const { data: orders_result } = await axios.post(`/orders`, items);
            setResults(orders_result?.result || null)
            setTimeout(() => handlePrint(), 250);
            push.show({ title: "Başarılı", color: "green", message: "Sipariş başarıyla oluşturuldu! Yazdırma birazdan başlayacak!" })
        } catch (error) {
            push.show({ title: "Uyarı", color: "red", message: error?.response?.data?.message || "Sistem Hatası!" })
        }
    }

    return (
        <section className="bg-slate-50 py-8">

            <div className="container mx-auto grid grid-cols-12">
                <div className="col-span-8 border-r border-r-slate-50 pr-3 mr-3 flex flex-col gap-y-8">
                    {/* Ticket Form */}
                    <div className="flex flex-col gap-y-3">
                        {tickets?.map(t => <TicketItem id={t.id} name={t?.name} price={t?.price} description={t?.description} />)}
                    </div>
                    {/* Oluşan Biletler */}
                    <div className="flex flex-col gap-y-3">
                        <h1 className="border-l-8 border-l-red-500 pl-2 text-lg font-bold text-slate-800">SON BİLDİRİMLER</h1>
                        <div className="bg-white rounded">
                            {notifications?.map(n => <NotificationItem title={n.title} description={n.description} />)}
                        </div>
                    </div>
                </div>
                <div className="col-span-4">
                    <div class="flex min-h-full flex-col bg-white p-8 border-4 border-slate-100">
                        <div class="">
                            <div class="flex items-start justify-between">
                                <h2 class="text-lg font-bold text-slate-900" id="slide-over-title">SEPET İÇERİĞİ</h2>
                            </div>
                            <div class="my-2">
                                <div class="flow-root">
                                    {carts?.length > 0 ? (
                                        <ul class="divide-y divide-slate-100">
                                            {carts?.map(c => <CartItem ticket_id={c.ticket_id} name={c.name} qty={c?.qty} price={c?.price} />)}
                                        </ul>
                                    ) : (
                                        <p>Sepette yeterince bilet bulunmuyor</p>
                                    )}
                                </div>
                            </div>
                        </div>
                        {carts.length > 0 && (
                            <div class="border-t border-slate-100 py-6">
                                <div class="flex justify-between text-base font-medium text-slate-900">
                                    <p className="text-slate-800 font-bold">Toplam</p>
                                    <p>₺ {toThousand(totalPrice)}</p>
                                </div>
                                <div class="my-5 h-px bg-slate-100"></div>
                                <div className="flex flex-col gap-y-4">
                                    <div class="flex flex-col justify-between text-base font-medium text-slate-900">
                                        <p className="text-slate-800 font-bold mb-2">Ödeme Yöntemi:</p>
                                        <Select value={user?.method} style={{ fontSize: 14 }} size="md" onChange={e => setUser(prev => ({ ...prev, method: e }))} data={[{ value: "pos", label: "POS" }, { value: "cash", label: "NAKİT" }, { value: "other", label: "DİĞER" }]} />
                                    </div>
                                    <div class="flex flex-col justify-between text-base font-medium text-slate-900">
                                        <p className="text-slate-800 font-bold mb-2">Misafir Adı:</p>
                                        <Input value={user?.fullname} styles={{ fontSize: 14 }} size="md" onChange={e => setUser(prev => ({ ...prev, fullname: e.target.value }))} type="text" />
                                    </div>
                                </div>
                                <div class="mt-3">
                                    <button onClick={handlePayment} class="w-full flex items-center justify-center rounded border border-transparent bg-green-500 px-6 py-3 text-base font-medium text-white shadow-sm">Ödemeyi Onayla</button>
                                </div>
                                <div class="flex flex-col justify-center text-center text-sm text-slate-500">
                                    <div class="py-4">yada</div>
                                    <button onClick={clearItems} type="button" class="font-medium text-slate-600 hover:text-slate-500">Sepeti Temizle!<span aria-hidden="true"> →</span></button>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>

            <div className="hidden">
                <PrintPage reff={componentRef} items={results} />
            </div>

        </section>
    )
}

export default Dashboard;
