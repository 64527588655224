import React from 'react';
import ReactDOM from 'react-dom/client';
import './assets/css/index.css';
import { MantineProvider } from '@mantine/core';
import { Notifications } from '@mantine/notifications';
import Navigation from './routes';
import { BrowserRouter } from 'react-router-dom';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <MantineProvider theme={{}}>
        <Notifications position="top-right" zIndex={1000} limit={5} autoClose={3500} />
        <Navigation />
      </MantineProvider>
    </BrowserRouter>
  </React.StrictMode>
);