import axios from 'axios';
import useAuthStore from '../stores/auth';

const axios_client = axios.create({
    baseURL: "https://gate-api.fibacode.com/api/v1/groot"
});

axios_client.interceptors.request.use(config => {
    let token = useAuthStore.getState().token;
    if (token) config.headers.authorization = token
    return config;
}, err => err);

axios_client.interceptors.response.use((res) => res, (err) => {
    if (err?.response?.status === 401) {
        delete axios_client.defaults.headers.authorization;
        window.location.href = "/"
    }
    throw err;
});

export default axios_client;