import React, { useEffect, useRef, useState } from 'react';

import { createColumnHelper } from '@tanstack/react-table';
import { LoadingOverlay } from '@mantine/core';
import { RiQrCodeLine, RiRefundLine, RiTeamLine } from "react-icons/ri";

import { notifications as push } from '@mantine/notifications';

import DataTable from '../components/table';

import axios from '../helpers/axios';
import PrintPage from '../components/print';
import { useReactToPrint } from 'react-to-print';

export default function TransactionList() {

    const [tickets, setTickets] = useState([])
    const [ticket, setTicket] = useState(null)
    const [loading,] = useState(false);

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
        onAfterPrint: () => {
            setTicket(null)
        },
        onPrintError: () => {
            push.show({ title: "Uyarı", color: "red", message: "Bilet yazdırılırken bir sorun oluştu! Lütfen yeniden deneyin!" })
        }
    });

    useEffect(() => {
        getTransactions();
    }, [])

    const getTransactions = async () => {
        try {
            const { data: orders_result } = await axios.get("/orders");
            setTickets(orders_result?.orders)
        } catch (error) {
            push.show({ title: "Uyarı", color: "red", message: error?.response?.data?.error || "Sunucu Hatası" })
        }
    }

    const handleRefund = async (id, status) => {
        try {
            if (status) {
                push.show({ title: "Uyarı", color: "red", message: "Bu sipariş hali hazırda iade edilmiş!" })
                return
            }
            await axios.post(`/orders/${id}`);
            getTransactions();
        } catch (error) {
            push.show({ title: "Uyarı", color: "red", message: error?.response?.data?.error || "Sunucu Hatası" })
        }
    }

    const columnHelper = createColumnHelper()
    const columns = [
        columnHelper.accessor('id', {
            header: '#',
        }),
        columnHelper.accessor('fullname', {
            header: 'İSİM SOYİSİM',
            truncate: true,
            full: true,
        }),
        columnHelper.accessor('name', {
            header: 'BİLET TİPİ',
            truncate: true,
        }),
        columnHelper.accessor('qty', {
            header: 'ADET',
            truncate: true,
            cell: row => row.getValue() + " / " + row.row.original.used
        }),
        columnHelper.accessor('total', {
            header: 'TOPLAM',
            cell: row => "₺" + row.getValue()?.toFixed(2)
        }),
        columnHelper.accessor('refund_date', {
            header: 'İADE',
            truncate: true,
            cell: row => <span title={row.getValue()}>{row.getValue() ? "[E]" : "[H]"}</span>
        }),
        columnHelper.accessor('created_at', {
            header: 'TARİH',
            truncate: true,
            cell: row => row.getValue() ? row.getValue() : "-"
        }),
        columnHelper.accessor('id', {
            header: 'EYLEMLER',
            cell: (row) => {
                let isRefunded = row.row.original.refund_date;
                return (
                    <div className='w-max grid grid-cols-2 gap-x-2'>
                        <button onClick={() => {
                            setTicket({ code: row.row.original.barcode, fullname: row.row.original.position, ticket_type: row.row.original.name, position: row.row.original.id })
                            setTimeout(() => handlePrint(), 250);
                        }} className='flex cursor-pointer w-8 h-8 bg-blue-500 rounded flex flex-row items-center justify-center'>
                            <RiQrCodeLine color="white" size={16} />
                        </button>
                        <button onClick={() => handleRefund(row.getValue(), isRefunded)} className={`flex cursor-pointer w-8 h-8 ${isRefunded ? "bg-slate-200" : "bg-red-500"} rounded flex flex-row items-center justify-center`}>
                            <RiRefundLine color={isRefunded ? "black" : "white"} size={16} />
                        </button>
                    </div>
                )
            }
        }),
    ]

    return (
        <div className="bg-slate-50 muhsin flex flex-col">

            <LoadingOverlay className='fixed left-0 right-0 top-0 bottom-0 z-[100]' visible={!!loading} overlayBlur={5} />

            <div className='flex-1 flex flex-col'>

                <div className='bg-red-500'>
                    <div className='container mx-auto flex flex-row items-center py-6'>
                        <RiTeamLine color="white" size={18} />
                        <h1 className='text-white text-lg font-light pl-2'>BİLET YÖNETİMİ</h1>
                    </div>
                </div>

                <div className='container mx-auto grid grid-cols-1 gap-x-6 gap-y-3 py-6'>

                    <div className='bg-white rounded shadow-sm border border-slate-100 border-b-transparent'>
                        <div className='h-[70px] flex flex-row items-center justify-between px-6 border-b border-b-slate-100'>
                            <div className='flex-1 flex flex-row'>
                                <h1 className='text-lg font-bold'>KASİYER BİLETLERİ</h1>
                            </div>
                        </div>
                        <div className='w-full overflow-x-auto'>
                            <DataTable searchable columns={columns} data={tickets} />
                        </div>
                    </div>

                </div>

            </div>

            <div className="hidden">
                <PrintPage reff={componentRef} items={[ticket]} />
            </div>

        </div>
    );
}