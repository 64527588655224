/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { notifications } from '@mantine/notifications';
import axios from "../helpers/axios";
import useAuthStore from '../stores/auth';
import { Input, Select } from '@mantine/core';

const Login = () => {

    const { setToken } = useAuthStore()
    const navigate = useNavigate()

    useEffect(() => {
        setToken(null)
    }, [])

    const [credentials, setCredentials] = useState({ username: "", password: "" });

    const handleLogin = async () => {
        try {
            const { data: login_result } = await axios.post(`/auth/login`, credentials);
            console.log(login_result)
            setToken(login_result?.token)
            navigate("/dashboard")
        } catch (error) {
            notifications.show({ title: "Uyarı", color: "red", message: error?.response?.data?.message || "Sistem Hatası!" })
        }
    };

    return (
        <div className="min-h-screen flex items-center justify-center bg-slate-50 py-12 px-4 sm:px-6 lg:px-8">
            <div className="max-w-sm w-full">
                <div className='flex flex-col items-center justify-center'>
                    <img src="/logo.svg" width={200} alt="" />
                </div>
                <form className="mt-8">

                    <input type="hidden" name="remember" value="true" />

                    <div className="rounded-md shadow-sm">
                        <div className="-mt-px">
                            <Input size='md' value={credentials.username} onChange={val => setCredentials(prev => ({ ...prev, username: val.target.value }))} placeholder='Kullanıcı Adı' styles={{ input: { borderRadius: 0, border: 0, height: 55 } }} className="w-full border border-border-0 placeholder-bg0 font-medium focus:outline-none sm:text-sm sm:leading-5" />
                        </div>
                        <div className="-mt-px">
                            <Input type='password' size='md' value={credentials.password} onChange={val => setCredentials(prev => ({ ...prev, password: val.target.value }))} placeholder='Şifre' styles={{ input: { borderRadius: 0, border: 0, height: 55 } }} className="w-full border border-border-0 placeholder-bg0 font-medium focus:outline-none sm:text-sm sm:leading-5" />
                        </div>
                    </div>

                    <div className="flex items-center justify-between my-4">
                        <div className="flex items-center">
                            <input id="remember_me" type="checkbox" className="form-checkbox h-4 w-4 text-slate-800" />
                            <label htmlFor="remember_me" className="ml-2 block text-sm leading-5 text-slate-800">Beni Hatırla</label>
                        </div>
                    </div>

                    <div className="cursor-pointer text-center w-full rounded-sm py-3 px-4 text-sm font-bold text-white bg-red-500 hover:bg-red-600 focus:outline-none" onClick={handleLogin}>
                        Giriş Yap!
                    </div>

                </form>
            </div>
        </div>
    );
}

export default Login;